import './PageHeader.css';

export default function PageHeader(props) {
  return(
  <div>
    <header className='pageheader'>
        <div className="header-container">
            <h1 className='headline-1'>{props.h01}<br/>{props.h02}</h1>
            <p className='paragraph'>{props.p}</p>
        </div>
        <img className="background" src="./assets/background-lines.svg" alt="achtergrond-lijnen" />      
    </header>
  </div>
  );
}