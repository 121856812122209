import { useEffect, useState } from "react";
import { useCallback } from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import './Navigation.css';
import { slide as Menu } from 'react-burger-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


export default function Navigation() {
    const [navbar, setNavbar] = useState(false)
    const [open, setOpen] = useState(false)

    const changeBackground = useCallback(() => {
        if (window.scrollY >= 66) {
            setNavbar(true)
          } else {
            setNavbar(false)
          }
    },[])

    useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
    })

    const handleStateChange = (state) => {
        setOpen(state.isOpen) 
    }
    
    // This can be used to close the menu, e.g. when a user clicks a menu item
    const closeMenu = ()=> {
        setOpen(false)
    }

    return (
        <>
            <header className={navbar ? "navigation active" : "navigation"}>
                <div className="container" id="outer-container">
                    <div className="navigation--logo">
                        <img src="/assets/logo_zwart.png" alt="TUURBODESIGNS Logo" className="logo" />

                        <div className="logo-title"><Link to="/">TUURBODESIGNS</Link></div>
                    </div>
                    <nav className="navigation--nav">
                        <ul>
                            <li>
                                <NavLink to="/" >Welkom</NavLink>
                            </li>
                            <li>
                                <NavLink to="/realisaties">Realisaties</NavLink>
                            </li>
                            <li>
                                <NavLink to="/over">Over ons</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact">Contact</NavLink>
                            </li>
                        </ul>
                    </nav>

                    <Menu right disableAutoFocus burgerButtonClassName={"nav--hamburger"} 
                    customBurgerIcon={ <img src="./assets/hamburger.svg" className="" alt="phone-menu"/>} 
                    customCrossIcon={ <FontAwesomeIcon icon={solid('times')} color="#EFEFEF"/>}
                    
                    isOpen={open}
                    onStateChange={(state) => handleStateChange(state)}
                    >
                        <ul>
                            <li>
                                <Link to="/" onClick={() => closeMenu()}>Welkom</Link>
                            </li>
                            <li>
                                <Link to="/realisaties" onClick={() => closeMenu()}>Realisaties</Link>
                            </li>
                            <li>
                                <Link to="/over" onClick={() => closeMenu()}>Over ons</Link>
                            </li>
                            <li>
                                <Link to="/contact" onClick={() => closeMenu()}>Contact</Link>
                            </li>
                        </ul>
                    </Menu>
                </div>
            </header>            
            <Outlet />
        </>
    )
}