import './Services.css';
import {Link} from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default function Services() {
    return (
        <section className="services">
            <div className="container">
                <h2 className="headline-2">Wij zorgen voor <span>website …</span></h2>
                <div className="services--services-wrapper">
                    <div className="service-item" data-aos="fade-left">
                        <div className="service-image">
                            <img src="./assets/design.svg" alt="Website design illustratie" />
                        </div>
                        <div className="service-description">
                            <h3 className="headline-3">Design</h3>
                            <p className="paragraph">We ontwerpen en maken unieke websites die u wenst.</p>
                        </div>
                    </div>
                    <div className="service-item" data-aos="fade-left" data-aos-delay="250">
                        <div className="service-image">
                            <img src="./assets/redesign.svg" alt="Website redesign illustratie" />
                        </div>
                        <div className="service-description">
                            <h3 className="headline-3">Redesign</h3>
                            <p className="paragraph">Heeft u een verouderde website? Geen zorgen, wij blazen er nieuw leven in.</p>
                        </div>
                    </div>
                    <div className="service-item" data-aos="fade-left"data-aos-delay="500">
                        <div className="service-image">
                            <img src="./assets/maintanance.svg" alt="Website design illustratie" />
                        </div>
                        <div className="service-description">
                            <h3 className="headline-3">Onderhoud</h3>
                            <p className="paragraph">Wens je een aanpassing? Laat het ons weten.</p>
                        </div>
                    </div>
                </div>
                <Link to="/realisaties" className='btn btn-white arrow'>Realisaties</Link>
                <div className="clear"></div>
            </div>
        </section>
    )
}