import './OverTUURBO.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default function OverTUURBO() {
  return (
    <section className="overons-container">
      <div className="container overons-grid">
          <div className="overons-content">
            <h2 className='headline-2'>Over TUURBODESIGNS</h2>
            <p className='content-p paragraph'>Wij zijn twee gemotiveerde bachelorstudenten van de Hogeschool HoGent. 
              We hebben beiden een grote interesse in het ontwerpen en ontwikkelen van 
              websites. Na verschillende projecten voor onszelf en vrienden & familie, 
              groeide het idee om te verkopen wat we graag doen. Op dat moment besloten 
              we TUURBODESIGNS op te richten en kleine bedrijven te helpen door mooie en 
              betaalbare websites voor hen te maken.</p>
            <div className='punten'>
              <h2 className="headline-2">Onze sterke punten</h2>
              <ul className='list'>
                <li><p>We nemen onze tijd voor elk project</p></li>
                <li><p>Elke website die we maken is uniek</p></li>
                <li><p>Prijs/kwaliteit gegarandeerd</p></li>
                <li><p>Integratie van het bedrijfskarakter in de website</p></li>
                <li><p>We zijn gedreven en gemotiveerd</p></li>
              </ul>
            </div>
          </div>
          <div className="banner" data-aos="fade-left">
            <img src="./assets/overons-banner-min.jpeg" alt="overons banner" />
          </div>
        </div>
    </section>
  );
}