import { Link } from 'react-router-dom';
import './Landing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands} from '@fortawesome/fontawesome-svg-core/import.macro';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default function Landing() {
    return (
        <main className="landing">
            <div className="container">
                <div className="landing--headers">
                    <h1 className="headline-1" data-aos="fade-up">Dare to innovate, innovate to grow</h1>
                    <div className="cta">
                        <p className="paragraph" data-aos="fade-up" data-aos-delay="200">Geef je bedrijf een boost met een unieke website op maat gemaakt.</p>
                        <Link to="/realisaties" className='btn btn-primary' data-aos="fade-up" data-aos-delay="400">Ontdek het hier</Link>
                        <div className="socials">
                            <a href="https://linkedin.com" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('linkedin-in')} className="social-icon" size='lg'/></a>
                            <a href="https://instagram.com" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={brands('instagram')} className="social-icon" size='lg'/></a>
                        </div>
                    </div>
                </div>
                <div className="landing--decoration">
                    <div className="image-wrapper">
                        <img className='picture' src="./assets/landing-min.jpeg" alt="?" />
                    </div>
                    <div className="background-wrapper">
                        <img className='background-lines' src="./assets/background-lines-green.svg" alt="Lijnen" />
                        <img className='placeholder' src="./assets/TUURBO_PLACEHOLDER.svg" alt="Placeholder" />
                    </div>
                </div>
            </div>
        </main>
    )
}