import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './pages/Layout.jsx';
import Home from './pages/Home.jsx';
import NotFound from './pages/NotFound.jsx';
import Realisaties from './pages/Realisaties.jsx';
import OverOns from './pages/OverOns.jsx';
import Contact from './pages/Contact.jsx';
import ScrollToTop from './ScrollToTop';
import Policy from './pages/Policy';
import Terms from './pages/Terms';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="realisaties" element={<Realisaties />} />
          <Route path="over" element={<OverOns />} />
          <Route path="contact" element={<Contact />} />
          <Route path="policy" element={<Policy />} />
          <Route path="terms" element={<Terms />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
