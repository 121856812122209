import './GetToKnowUs.css';
import {Link} from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default function GetToKnowUs() {
    return (
        <section className="get-to-know-us">
            <img className='our-team' src="./assets/about-3-min.jpeg" alt="Ons team" />
            <div className="container">
                <div className="about-1" data-aos="zoom-in"><img src="./assets/about-1-min.jpeg" alt="Over TUURBODESIGNS team" /></div>
                <div className="about-2" data-aos="zoom-in" data-aos-delay="250"><img src="./assets/about-3-min.jpeg" alt="Over TUURBODESIGNS team" /></div>
                <div className="about-3" data-aos="zoom-in" data-aos-delay="500"><img src="./assets/about-2-min.jpeg" alt="Over TUURBODESIGNS team" /></div>
                <div className="get-to-know-us--description">
                    <h3 className="headline-2">Leer ons kennen</h3>
                    <p className="paragraph">Benieuwd naar ons persoonlijk verhaal en wie we zijn, alsook het verhaal achter TUURBODESIGNS?</p>
                    <Link to="/over" className='btn btn-primary'>Lees meer</Link>
                </div>
            </div>
        </section>
    )
}