import "./Work.css";

export const WorkBlock = ({ img, title, description, link }) => {
  return (
    <div className="work">
      <div class="content-overlay"></div>
      <img class="webbanner" src={`/assets/projects/${img}`} alt={`${title}`} />

      <div class="content-details fadeIn-bottom">
        <h3 class="content-title headline-3">{title}</h3>
        <p class="content-text paragraph">{description}</p>
        <a
          href={link}
          className="btn btn-white"
          target={"_blank"}
          rel="noreferrer"
        >
          Bekijk
        </a>
      </div>
    </div>
  );
};

export default function Work() {
  return (
    <div className="z-index">
      <div className="work-container">
        <WorkBlock
          img={"adriansens_thumbnail.png"}
          title={"Adriansens bv"}
          description={"Website voor legal expert Wouter Adriannsens"}
          link={"https://adriansens.be/"}
        />
        <WorkBlock
          img={"reyndersconsult.png"}
          title={"Reynders Consult"}
          description={"Website voor studiebureau 'Reynders Consult'"}
          link={"https://reyndersconsult.be/"}
        />

        <WorkBlock
          img={"rijlesopmaat.png"}
          title={"Rijles op maat"}
          description={"Website voor rijschool 'Rijles op maat'"}
          link={"https://rijlesopmaat.be/"}
        />

        <WorkBlock
          img={"thiboportfolio-min.png"}
          title={"Personal Portfolio"}
          description={"Persoonlijke website van Thibo De Reu."}
          link={"https://thibodereu.be/"}
        />

        <WorkBlock
          img={"arthurportfolio-min.png"}
          title={"Personal Portfolio"}
          description={"Persoonlijke website van Arthur De Letter."}
          link={"https://arthurdeletter.be/"}
        />
      </div>

      {/* <div className='work-container-reversed'>
       Aan te vullen
      </div> */}
    </div>
  );
}
