
import Ctablock from "../components/Ctablock";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import Work from "../components/Work";

export default function Realisaties() {
    return (
        <div className="realisaties">
            <PageHeader h01={"Onze realisaties"} p={"Laat u inspireren door een paar van onze vorige projecten."}/>                
            <Work/>
            <Ctablock s={"primary"}  h301={"Bent u geïnspireerd door ons werk?"} h302={"Vraag hier een gratis en vrijblijvende offerte aan."} cta={"Offerte Aanvragen"} btn={'btn-primary'}/>
            <Footer/>
        </div>
    )
} 