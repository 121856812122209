import React from 'react'
import { Link } from 'react-router-dom';
import './NotFound.css'

const NotFound = () => {
  return (
    <div className='not-found'>
      <div className="background-lines--container"></div>
      <div className="container not-found--container">
        <div className="not-found--inner">
          <h1>Oops...</h1>
          <div className="not-found--cards">
            <div className="cards--item">
              <div className="card--smiley">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-frown"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
              </div>
              <h4 className="card--title">Het slechte nieuws</h4>
              <p className="card--description">De pagina die je probeert te bereiken, bestaat niet.</p>
              <Link to={"/"} className="btn btn-primary">Terug naar home</Link>
            </div>
            
            <div className="cards--item">
              <div className="card--smiley">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smile"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
              </div>
              <h4 className="card--title">Het goede nieuws</h4>
              <p className="card--description">We kunnen ook een 404 pagina ontwerpen!</p>
              <Link to={"/realisaties"} className="btn btn-primary">Bekijk realisaties</Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound