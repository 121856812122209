 import './Ctablock.css';
import { Link } from 'react-router-dom';

export default function Ctablock(props) {
  return(
  
    <div className={props.s}>
      <div className='title-container'>
        <h3 className='headline-3'>{props.h301}</h3>
        <h3 className='headline-3'>{props.h302}</h3>
      </div>
      <Link to="/contact" className={`btn ` + props.btn}>{props.cta}</Link>
    </div>
  
  );
}