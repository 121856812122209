import Landing from "../components/Landing";
import Services from "../components/Services";
import Footer from "../components/Footer"
import GetToKnowUs from "../components/GetToKnowUs";
import Testimonials from "../components/Testimonials";
import CtaImage from "../components/CtaImage";

export default function Home() {
    return (
        <>
            <Landing/>
            <Services/>
            <GetToKnowUs/>
            <Testimonials/>
            <CtaImage/>
            <Footer />
        </>
    )
}