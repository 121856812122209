import './ContactBody.css';
import { useForm } from "react-hook-form";
import React, { useCallback, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands} from '@fortawesome/fontawesome-svg-core/import.macro'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function ContactBody() {
  const MySwal = withReactContent(Swal)
  const { register, reset, formState: { errors }, handleSubmit } = useForm();
  const form = useRef();
  const recaptchaRef = useRef(null);

  const [subject, setSubject] = useState("andere");
    
  const sendEmail = async(e) => {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const params = {
      mail: e.mail, 
      naam: e.naam,
      onderwerp: e.onderwerp,
      bericht: e.bericht,
      'g-recaptcha-response': token,
    };
    
    emailjs.send('service_44s4xly', 'template_5ml0u89', params, 'VD4qVsiMZz46dK7Ml')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
    });

    MySwal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Email succesvol verzonden!',
      showConfirmButton: false,
      timer: 1500,
      width: 450,
    })

    reset();
    setSubject("andere");
  };

  const selectSubject = useCallback((e) => {
    setSubject(e.target.value);
  }, [])
  

  return (
    <div className='full-contact-body'>
      <div className="container contact-body">
        <div className="maildiv">
          <h2 className="headline-2">Laat een bericht achter</h2>
          <form ref={form} onSubmit={handleSubmit(sendEmail)} className="form">
            <div className="flex-vertical">
              <label className="label" htmlFor="naam">Naam<sup>*</sup></label>
              <input className='input' id="naam" placeholder='Naam..' autoComplete='off' {...register("naam", { required: true })} />
              {errors.naam?.type === 'required' && <p className="error">Naam is verplicht</p>}
            </div>
            
            <div className="flex-vertical">
              <label className="label"htmlFor="mail">Mail<sup>*</sup></label>
              <input type="email" className='input' id="mail" placeholder='Mail..' {...register("mail", { required: true })} />
              {errors.mail?.type === 'required' && <p className="error">Mail is verplicht</p>}
            </div>

            <div className="flex-radiobuttons">
              <label className="label" htmlFor="">Onderwerp<sup>*</sup></label>
              <ul>
                <li>
                  <input className='custom-radio' id='design' name="subject" {...register('onderwerp', { required: true })} type="radio" value="design" onChange={selectSubject} checked={subject === "design"}/>
                  <label className='custom-label' htmlFor="design">Design</label>
                </li>
  
                <li>
                  <input className='custom-radio' id='redesign' name="subject" {...register('onderwerp', { required: true })} type="radio" value="redesign" onChange={selectSubject} checked={subject === "redesign"}/>
                  <label className='custom-label' htmlFor="redesign">Redesign</label>
                </li>
  
                <li>
                  <input className='custom-radio' id='andere' name="subject" {...register('onderwerp', { required: true })} type="radio" value="andere" onChange={selectSubject} checked={subject === "andere"}/>
                  <label className='custom-label' htmlFor="andere">Andere</label>
                </li>
              </ul>
            </div>

            <div className="flex-vertical">
              <label className="label" htmlFor="bericht">Bericht<sup>*</sup></label>
              <textarea className='input' id="bericht" placeholder='Bericht..' {...register("bericht", {required: true})} />
              {errors.bericht?.type === 'required' && <p className="error">Bericht is verplicht</p>}
            </div>
            
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6LciWZgeAAAAAAOjU9ZjaoV4RMDEmoi6AcAhKyxQ"
            />            

            <div className='mailbtn'>
              <input className="btn btn-primary" type="submit" value={"Verstuur Bericht"}/>
            </div>
          </form>

          
        </div>
 
        <div className="kanalen">
          <h2 className="headline-2">Andere kanalen</h2>
          <div className="social-card">
            <div className='card-icon'><FontAwesomeIcon icon={brands('instagram')} className="social-icon" size='2x'/></div>
            <div className="card-content">
              <a href="https://www.instagram.com/tuurbodesigns/" className="card-title" target="_blank" rel="noreferrer">DM ons op Instagram</a>
              <span></span>
              <p className="card-body">Je kunt ons altijd een DM sturen op Instagram voor snelle vragen.</p>
            </div>
          </div>

          <div className="social-card">
            <div className='card-icon'><FontAwesomeIcon icon={brands('linkedin-in')} className="social-icon" size='2x'/></div>
            <div className="card-content">
              <a href="https://www.linkedin.com/company/tuurbodesigns" className="card-title" target="_blank" rel="noreferrer">Connect met ons op LinkedIn</a>
              <span></span>
              <p className="card-body">Breid je netwerk uit en groei met ons mee op LinkedIn.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}