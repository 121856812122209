import './Team.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands} from '@fortawesome/fontawesome-svg-core/import.macro'
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export default function Team() {
  return (
    <div className='big-team-container'>
      <div className="container">
        <h2 className="headline-2">Het team</h2>

        <div className="profile-cards-container">
          <div className="profile-card" data-aos="fade-right">
            <img src="./assets/arthur-min.jpeg" alt="profielfoto" className='profilepic'/>
            <div className="profile-content">
              <p className="name paragraph">Arthur De Letter</p>
              <p className="profile-info paragraph">Ik hou ervan mijn kennis en ervaring te gebruiken om anderen te helpen.</p>
              <div className="ctas">
                <div className="socials">
                  <a href='https://www.instagram.com/arthurdeletter/' target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={brands('instagram')}  className="social" size='lg'/></a>
                  <a href='https://www.linkedin.com/in/arthur-de-letter-a9a221184/' target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={brands('linkedin-in')} className="social" size='lg'/></a>
                </div>
                <div className="port">
                  <a className='arrow' href="https://arthurdeletter.be" target="_blank" rel="noreferrer">Bekijk Portfolio</a>
                </div>
              </div>
            </div>
          </div>

          <div className="profile-card end" data-aos="fade-right">
            <img src="./assets/thibo-min.jpeg" alt="profielfoto" className='profilepic'/>
            <div className="profile-content">
              <p className="name paragraph">Thibo De Reu</p>
              <p className="profile-info paragraph">Ik ben super enthousiast om ervaring op te doen en te groeien als persoon.</p>
              <div className="ctas">
                <div className="socials">
                  <a href='https://www.instagram.com/thibo_de_reu/' target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={brands('instagram')}  className="social" size='lg'/></a>
                  <a href='https://www.linkedin.com/in/thibo-de-reu-27b335206/' target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={brands('linkedin-in')} className="social" size='lg'/></a>
                </div>
                <div className="port">
                  <a className='arrow' href="https://thibodereu.be" target="_blank" rel="noreferrer">Bekijk Portfolio</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}