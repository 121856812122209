import ContactBody from "../components/ContactBody";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
 
export default function Contact() {
    return (
        <div className="contact">
            <PageHeader h01={"We horen graag van je"} p={"Aarzel niet om ons te contacteren."}/>
            <ContactBody/>
            <Footer/>
        </div>
    )
}