import { Link } from 'react-router-dom';
import './CtaImage.css';

export default function CtaImage() {
    return (
        <section className="cta-contact">
             <div className="overlay">
                <div className="container">
                    <div className="cta-contact--description">
                        <h3 className="headline-3">Onduidelijkheden of vragen? <br/>We delen onze antwoorden graag met jou.</h3>
                        <Link to="/contact" className='btn btn-primary'>Contacteer Ons</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}