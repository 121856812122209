import './Testimonials.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import { useCallback } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

export const Testimonial = ({fullName, rating, description, delay}) => {

    const printStars = useCallback(() => {
        let stars = [];

        for (let index = 1; index <= 5; index++) {
            if (index <= rating) stars.push(<FontAwesomeIcon icon={solid('star')} size='xs' className='testimonial--star active' />);
            else stars.push(<FontAwesomeIcon icon={solid('star')} size='xs' className='testimonial--star' />);
        }

        return stars;
    },[rating])

    return (
        <div className="testimonial" data-aos="fade-left" data-aos-delay={delay}>
            <p className="testimonial--name">{fullName}</p>
            <div className="testimonial--rating">
                {printStars(rating).map(star => {
                    return star;
                })}
            </div>
            <p className="paragraph-small testimonial--description">
                {description}
            </p>
        </div>
    )
}

export default function Testimonials() {
    return (
        <section className="testimonials">
            <div className="container">
                <h2 className="headline-2">Wat onze klanten vinden</h2>
                <div className="testimonial--wrapper">
                    <Testimonial fullName="Rijles op maat" rating={5} description="Snelle service, correcte afhandeling, hulp bij technische problemen, mooie site, continu bereikbaar en steeds bereid om aan te passen! Super tevreden!" delay={0}/>
                    <Testimonial fullName="Reynders Consult" rating={5} description="Na jaren via informele contacten opdrachten binnengehaald te hebben, toch maar besloten om eindelijk eens aanwezig te zijn op het web. Ik vertrouwde het ontwerp van de website toe aan TUURBODESIGNS. Ze hebben me aangenaam verrast. Een frisse website die heel toegankelijk is. Ik kreeg veel positieve reacties." delay={500}/>
                </div>
            </div>
        </section>
    )
}