import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import Ctablock from "../components/Ctablock";
import OverTUURBO from "../components/OverTUURBO";
import Team from "../components/Team";
 
export default function OverOns() {
    return (
        <div className="overons">
            <PageHeader h01={"Leer ons kennen"} p={"Kom te weten wie we zijn en wat we doen."}/>
            <OverTUURBO/>
            <Ctablock s={"secondary"}  h301={"Interesse in een samenwerking met TUURBODESIGNS?"} h302={"Contacteer ons vrijblijvend hieronder."} cta={"Contacteer ons"} btn={'btn-white'}/>
            <Team/>
            <Footer/>
        </div>
    )
}